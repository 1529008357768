<script setup lang="ts">
// Packages
import { storeToRefs } from 'pinia';
import { useAlertsStore } from '@/store/alerts';

const colorMode = useColorMode();
const { t } = useI18n();
const isOpen = ref(false);
const store = useAlertsStore();
const { quantity } = storeToRefs(store);

const links = computed(() => [
  {
    label: t('HOME'),
    icon: 'i-heroicons-home',
    to: '/',
  },
  {
    label: t('USERS'),
    icon: 'i-heroicons-user-group',
    to: '/users',
  },
  {
    label: t('FAQS'),
    icon: 'i-heroicons-question-mark-circle',
    to: '/faqs',
  },
  {
    label: t('EVENTS'),
    icon: 'i-heroicons-calendar-days',
    to: '/events',
  },
  {
    label: t('BLOG_CATEGORIES'),
    icon: 'i-heroicons-building-library',
    to: '/blog/categories',
  },
  {
    label: t('BLOG_POSTS'),
    icon: 'i-heroicons-document-text',
    to: '/blog/posts',
  },
]);

const linksMobile = computed(() => links.value.map((l) => ({ ...l, click: toggleMnMb })));

function toggleMnMb() {
  isOpen.value = !isOpen.value;
}

</script>

<template>
  <div>
    <div>
      <nuxt-link to="/">
        <img :src="`/assets/${colorMode.value === 'light' ? 'logo-dark.png' : 'logo.webp'}`"
          alt="Terreiro Vó Rita das Almas logo" class="logo my-2 block">
      </nuxt-link>
      <UVerticalNavigation :links="links" class="mt-9" />
    </div>

    <ClientOnly>
      <Teleport to="#mobile_menu">
        <UButton :padded="false" color="gray" variant="link" icon="i-heroicons-bars-3-solid" @click="toggleMnMb" />
      </Teleport>
    </ClientOnly>

    <ClientOnly>
      <Teleport to="body">
        <USlideover v-model="isOpen" side="left">
          <div class="p-4 flex-1">
            <div class="flex justify-between justify-items-center">
              <nuxt-link to="/" @click="toggleMnMb">
                <img :src="`/assets/${colorMode.value === 'light' ? 'logo-dark.png' : 'logo.webp'}`"
                  alt="Terreiro Vó Rita das Almas logo" class="logo my-2 block">
              </nuxt-link>

              <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="self-center"
                @click="isOpen = false" />
            </div>
            <UVerticalNavigation :links="linksMobile" class="mt-9" />
          </div>
        </USlideover>
      </Teleport>
    </ClientOnly>
  </div>
</template>
<style lang="scss" scoped>
.logo {
  width: 140px;
}
</style>
